import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { animated, useSpring, config } from "@react-spring/web"



const HeroHeader = () => {

    const fadeDownProps = useSpring({
        config: config.slow,
        from: { opacity: 0, transform: `translate3d(0, -60px, 0)` },
        to: { opacity: 1, transform: `translate3d(0, 0, 0)` },
      })

      const fadeUpProps = useSpring({
        config: config.slow,
        from: { opacity: 0, transform: `translate3d(0, 60px, 0)` },
        to: { opacity: 1, transform: `translate3d(0, 0, 0)` },
      })

    return (
        <div className="relative">
            <StaticImage
                src="./sunset-blurred-darken.jpg"
                layout="fullWidth"
                alt="Sun rising in front of shadow of person reaching up"
            />
            <div className="absolute
            top-0
            right-0
            left-0
            text-white
            mt-3
            xl:mt-6
        ">
                <div className="mx-auto max-w-5xl px-2 sm:px-6 xl:px-8">
                    <animated.div style={fadeDownProps} className="
                    text-shadow-3xl
                    font-bold
                    mb-2
                    text-xl
                    md:leading-tight
                    md:mb-6
                    md:text-6xl
                    xl:text-9xl
                    xl:mb-4
                    xl:leading-tight
                ">
                        <h1>Integrative Wellness</h1>
                    </animated.div>
                    <animated.div style={fadeUpProps}>
                        <p className="text-shadow-2xl text-lg md:text-5xl mb-1 md:mb-2 xl:mb-4">
                            Professional Therapy <br /> Mental Health Services
                        </p>
                        <p className="text-shadow-2xl text-md md:text-3xl mb-2 md:mb-8 xl:mb-28 md:max-w-4xl">
                            Sioux Falls, SD
                        </p>
                    </animated.div>
                </div>
            </div>
        </div>
    )
}

export default HeroHeader;