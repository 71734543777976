import * as React from "react";
import { Helmet } from "react-helmet";
import {
  GatsbyImage,
  IGatsbyImageData,
  StaticImage,
} from "gatsby-plugin-image";
import AnimatedHeader from "../components/AnimatedHeader/AnimatedHeader";
import HeroHeader from "../components/HeroHeader/HeroHeader";
import Menu from "../components/Menu/Menu";
import Layout from "../components/Layout/Layout";
import { Link } from "gatsby";

// markup
const IndexPage = () => {
  return (
    <Layout full={true} removeTopSpace={true}>
      <Helmet title="Integrative Wellness" defer={false} />
      <div class="bg-green-300 p-4">
        <div class="container mx-auto text-center py-4">
          <h1 class="text-lg font-bold">New Location Coming Soon</h1>
          <p class="text-sm">September 16</p>
          <p class="text-sm">
            5010 S Broadband LN Ste 100, Sioux Falls, SD 57108
          </p>
        </div>
      </div>
      <HeroHeader />
      <div className="max-w-5xl mx-auto">
        <div className="px-6 lg:px-8 md:my-20 my-10">
          <p className="text-md md:text-3xl lg:text-6xl font-bold text-center md:leading-normal lg:leading-relaxed">
            We are a group of mental health professionals who serve everyday
            people with everyday problems.
          </p>
        </div>
      </div>
      <div className="py-10 md:py-20 bg-green-300 shadow-inner">
        <div className="max-w-5xl mx-auto px-6 lg:px-8">
          <h2 className="text-lg md:text-4xl font-bold md:text-center">
            We Offer Professional Therapy For:
          </h2>

          <ul className="grid-cols-2 md:grid-cols-3 grid mt-8 md:text-lg">
            <li>Depression</li>
            <li>Addiction</li>
            <li>Anxiety</li>
            <li>Grief and Loss</li>
            <li>Geriatrics</li>
            <li>Play Therapy</li>
            <li>Parenting</li>
            <li>ADD/ADHD</li>
            <li>Blended Family Issues</li>
            <li>Divorce</li>
            <li>Custody</li>
            <li>Borderline Personality Disorder</li>
            <li>Trauma and Abuse</li>
            <li>Family of Origin</li>
            <li>Gender Identity and Sexual Orientation</li>
          </ul>
        </div>
      </div>
      <div className="max-w-5xl mx-auto">
        <div className="my-10 md:my-20 text-center text-xl">
          <div className=" inline-block">
            <StaticImage
              src="../images/IntegrativeWellnesslogo.jpg"
              alt="Integrative Wellness logo"
              width={300}
              layout="fixed"
            />
          </div>
          <p className="mt-6">
            An association of independent therapists, not a partnership
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default IndexPage;
